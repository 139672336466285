import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

import LogoIcon from './../../../assets/images/logo.svg'

const Logo = ({
  className,
  width,
  height,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={LogoIcon}
            alt="Algovest Holdings LLC"
            width={width ? width : 32}
            height={height ? height : 32} />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;