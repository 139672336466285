import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
// import Cta from '../components/sections/Cta';
import SectionHeader from '../components/sections/partials/SectionHeader';

const Home = () => {
  const sectionHeader = {
    title: 'Want to know more?',
    paragraph: 'We are currently in stealth mode, so there is not much else we can say. However, you can stay tuned for more, or drop us a line at contact@algovest.io!'
  };

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <SectionHeader data={sectionHeader} className="center-content" />

      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;